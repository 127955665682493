





















import { defineComponent, onMounted, provide, reactive } from "@vue/composition-api";
import { getMyTeams } from "@/api/teamService";
import { getStudent } from "@/api/customerService";
import { userModule } from "@/store/modules/user";
import handleError from "@/helpers/errors";
import Learnlink from "@learnlink/interfaces";
import MyProjects from "@/components/customer/my-projects/MyProjects.vue";
import MyTeamsCustomer from "@/components/customer/MyTeamsCustomer.vue";
import UpdateStudentNotificationDialog from "@/components/customer/UpdateStudentNotificationDialog.vue";
import useAsyncData from "@/hooks/useAsyncData";

export default defineComponent({
  components: { UpdateStudentNotificationDialog, MyTeamsCustomer, MyProjects },
  name: "CustomerDashboard",
  setup() {
    const vm = reactive({
      updateStudentNotificationDialogVisible: false,
      studentsWithFakeEmail: [] as Learnlink.Student.default[],
    });
    const { isLoading, data: teams, refresh } = useAsyncData(() =>
      getMyTeams(userModule.state.userId)
    );

    async function mount() {
      try {
        const students = await Promise.all(
          userModule.state.user.students.map(async(uid) => await getStudent(uid))
        );
        vm.studentsWithFakeEmail = students.filter(student => student.account?.email.includes("@learnlink"));
        vm.updateStudentNotificationDialogVisible =
          !userModule.state.account.email.includes("@learnlink") &&
          vm.studentsWithFakeEmail.length > 0;
      }
      catch (e) {
        handleError(e);
      }
    }

    provide("getMyTeams", refresh);
    provide("getStudent", mount);

    onMounted(async() => {
      await mount();
    });

    return {
      isLoading,
      teams,
      vm,
    };
  }
});
