












































import { useRouter } from "@/router";
import { RoutesConfig } from "@/router/Routes";
import { computed, defineComponent, PropType } from "@vue/composition-api";
import { getActiveProjects } from "@/api/projectService";
import { userModule } from "@/store/modules/user";
import Learnlink from "@learnlink/interfaces";
import ClosedProjectCardCustomer from "./ClosedProjectCardCustomer.vue";
import OpenProjectCardCustomer from "./OpenProjectCardCustomer.vue";
import useAsyncData from "@/hooks/useAsyncData";

export default defineComponent({
  components: { OpenProjectCardCustomer, ClosedProjectCardCustomer },
  name: "MyProjects",
  props: {
    teams: {
      type: Array as PropType<Learnlink.Team.FullMetadata[]>,
      required: true,
    }
  },
  setup(props) {
    const { isLoading, data: projects } = useAsyncData(() =>
      getActiveProjects("customer", userModule.state.userId)
    );
    const noTeams = computed(() => props.teams.length === 0);
    const { navigate } = useRouter();

    return {
      isLoading,
      navigate,
      noTeams,
      projects,
      RoutesConfig,
    };
  },
});
