


















import { computed, defineComponent, PropType } from "@vue/composition-api";
import TeamCardCustomer from "@/components/customer/TeamCardCustomer.vue";
import Learnlink from "@learnlink/interfaces";
import SupportPhoneAlert from "../SupportPhoneAlert.vue";
import Moment from "@/services/moment";

export default defineComponent({
  name: "MyTeamsCustomer",
  components: { TeamCardCustomer, SupportPhoneAlert },
  props: {
    teams: {
      type: Array as PropType<Learnlink.Team.FullMetadata[]>,
      required: true,
    }
  },
  setup(props) {
    const filteredTeams = computed(() => props.teams?.filter(team => !team.previousTeamID || team.teamSessions[0].startTime < Moment().add(1, "week").unix()));
    return {
      filteredTeams,
    };
  }
});
