
































import Moment from "@/services/moment";
import { computed, defineComponent, PropType } from "@vue/composition-api";
import Learnlink from "@learnlink/interfaces";

export default defineComponent({
  name: "ClosedProjectCardCustomer",
  props: {
    project: {
      type: Object as PropType<Learnlink.Project.FullViewApp>,
      required: true,
    },
  },
  setup(props) {
    const createdReadable = computed(() => {
      return Moment.unix(props.project.created).format("Do MMMM");
    });

    const timelineItems = [
      {
        color: "success",
        fill: true,
        heading: "Takk for din bestilling, og gratulerer som medlem i Learnlink!",
        icon: "mdi-check-bold",
        description: "Du trenger ikke gjøre noe mer før vi kontakter deg."
      }
    ];

    if (props.project.channel !== "automaticOnboarding") {
      timelineItems.push({
        color: "primary",
        fill: true,
        heading: "Vi kontakter deg",
        icon: "mdi-numeric-2",
        description: "En av våre veiledere vil kontakte deg for å kartlegge ditt barns behov og ambisjoner."
      });

      timelineItems.push({
        color: "secondary",
        fill: false,
        heading: "Vi finner rett lærer til ditt barn",
        icon: "mdi-numeric-3",
        description: "Vi velger ut rett personlig lærer til å hjelpe dere."
      });

      timelineItems.push({
        color: "secondary",
        fill: false,
        heading: "Dere gjennomfører kartleggingstime",
        icon: "mdi-numeric-4",
        description: "Dere gjennomfører en kartleggingstime slik at vi kan lage en god fremdriftsplan for eleven. Her vil dere også få mulighet til å bli å bli kjent med personlig lærer."
      });
    }
    else {
      timelineItems.push({
        color: "primary",
        fill: true,
        heading: "Vi finner rett lærer til ditt barn",
        icon: "mdi-numeric-2",
        description: "Du trenger ikke foreta deg noe mer, vi er allerede i full gang med å finne den beste Personlige Læreren til deres behov. Personlig Lærer vil kontakte deg direkte."
      });

      timelineItems.push({
        color: "secondary",
        fill: false,
        heading: "Dere gjennomfører kartleggingstime",
        icon: "mdi-numeric-3",
        description: "Dere gjennomfører en kartleggingstime slik at vi kan lage en god fremdriftsplan for eleven. Her vil dere også få mulighet til å bli å bli kjent med personlig lærer."
      });
    }

    return {
      createdReadable,
      timelineItems
    };
  }
});
