

































































































































import AddStudentToTeamDialog from "@/components/customer/my-students-customer/AddStudentToTeamDialog.vue";
import { computed, defineComponent, PropType, reactive, onMounted, provide } from "@vue/composition-api";
import { createLinks } from "@/helpers/stringUtils";
import { getUnreadMessages } from "@/api/messageService";
import { userModule } from "@/store/modules/user";
import Credentials from "@/components/Credentials.vue";
import Learnlink from "@learnlink/interfaces";
import Messages from "../Messages.vue";
import TeamCardHeader from "@/components/shared/TeamCardHeader.vue";
import TeamInformation from "@/components/shared/TeamInformation.vue";
import TeamMembersCustomer from "@/components/customer/TeamMembersCustomer.vue";

export default defineComponent({
  name: "TeamCardCustomer",
  components: {
    AddStudentToTeamDialog,
    Credentials,
    TeamInformation,
    TeamMembersCustomer,
    TeamCardHeader,
    Messages,
  },
  props: {
    team: {
      type: Object as PropType<Learnlink.Team.FullMetadata>,
      required: true,
    },
  },
  setup(props) {
    const vm = reactive({
      createStudentDialogVisible: false,
      newPrivateMessages: 0,
      uid: userModule.state.userId,
    });

    onMounted(async() => {
      fetchUnreadMessages();
    });

    async function fetchUnreadMessages() {
      vm.newPrivateMessages = await getUnreadMessages(userModule.state.userId, props.team.sellers[0]);
    }

    provide("fetchUnreadMessages", fetchUnreadMessages);

    const students = computed(() => {
      return props.team.verboseStudents.filter((student) => {
        return student.parentUID === userModule.state.userId;
      });
    });

    const hasStudents = computed(() => props.team.verboseStudents.filter((students) => students.parentUID === vm.uid).length);

    const sellerName = computed(() => props.team.verboseSellers[0]?.firstName);

    const newTeamMessages = computed((): number => {
      const lastRead = userModule.state.user.lastReadTeamMessages?.filter(o => o.teamID === props.team.ID)[0]?.read;
      if (!lastRead) return props.team.information?.length || 0;
      return props.team.information?.filter(message => message.created > lastRead).length;
    });

    return {
      createLinks,
      minecraftCourse: computed(() => props.team.course.title.toLowerCase().includes("minecraft")),
      hasStudents,
      newTeamMessages,
      sellerName,
      students,
      vm,
    };
  }
});
