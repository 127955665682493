




























import CreateStudentCard from "@/components/customer/my-students-customer/CreateStudentCard.vue";
import Learnlink from "@learnlink/interfaces";
import { defineComponent, PropType, ref, watchEffect } from "@vue/composition-api";

export default defineComponent({
  name: "UpdateStudentNotificationDialog",
  components: { CreateStudentCard },
  props: {
    value: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
    students: {
      type: Array as PropType<Learnlink.Student.default[]>,
      required: true,
    }
  },
  setup(props, { emit }) {
    const _value = ref(props.value);
    watchEffect(() => {
      emit("input", _value);
    });
    return {
      _value,
    };
  }
});
