


























































import { getStudens } from "@/api/customerService";
import { addStudentToTeam } from "@/api/teamService";
import CreateStudentCard from "@/components/customer/my-students-customer/CreateStudentCard.vue";
import ProfilePhoto from "@/components/profile/ProfilePhoto.vue";
import handleError from "@/helpers/errors";
import useAsyncData from "@/hooks/useAsyncData";
import { useNotifier } from "@/providers/notifier";
import { userModule } from "@/store/modules/user";
import { defineComponent, inject, PropType, reactive, ref, watchEffect } from "@vue/composition-api";

export default defineComponent({
  name: "AddStudentToTeamDialog",
  components: { ProfilePhoto, CreateStudentCard },
  props: {
    value: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
    teamID: {
      type: String as PropType<string>,
      default: "",
    },
  },
  setup(props, { emit }) {
    const _value = ref(props.value);
    watchEffect(() => {
      emit("input", _value);
    });
    const notify = useNotifier();
    const getMyTeams = inject<() => Promise<void>>("getMyTeams");
    const vm = reactive({
      loading: false,
    });

    const { isLoading, data: students } = useAsyncData(() => getStudens(userModule.state.userId));

    async function addToTeam(uid: string) {
      vm.loading = true;
      try {
        await addStudentToTeam(props.teamID, uid);
        getMyTeams && await getMyTeams();
        notify({
          title: "Lagt til! 🥳",
          message: "Ditt barn kan nå logge inn på egen bruker for å motta utmerkelser og exp-poeng.",
          type: "success",
        });
        _value.value = false;
      }
      catch (e) {
        handleError(e);
        notify({
          title: "Noe gikk galt! 🤯",
          message: "Vennligst kontakt support hvis problemet vedvarer.",
          type: "error",
        });
      }
      vm.loading = false;
    }

    return {
      _value,
      addToTeam,
      isLoading,
      students,
      userModule,
      vm,
    };
  },
});
